<template>
  <v-layout column fill-height>
    <v-layout align-center mb-3 xs10 justify-center title>
      <Separador titulo="Envíanos un Correo" />
    </v-layout>
    <v-layout xs12 justify-center align-center>
      <v-btn-toggle
        v-model="formulario.juridico"
        mandatory
        align-center
        borderless
      >
        <v-btn :value="0" active-class="info">
          <span class="hidden-sm-and-down mr-1">Persona</span>
          Natural
        </v-btn>
        <v-btn :value="1" active-class="info">
          <span class="hidden-sm-and-down mr-1">Persona</span>
          Jurídica
        </v-btn>
      </v-btn-toggle>
    </v-layout>
    <v-layout grow ma-3 class="black--text" wrap justify-center align-center>
      <v-flex v-if="formulario.juridico == 1" px-5 xs3 sm2 md1>
        <v-select
          :items="['J-', 'E-', 'V-', 'P-', 'G-']"
          v-model="prerif"
        ></v-select>
      </v-flex>
      <v-flex v-if="formulario.juridico == 1" px-5 xs7 md9>
        <v-text-field
          type="text"
          label="Rif"
          v-model="formulario.rif"
        ></v-text-field>
      </v-flex>
      <v-flex px-5 xs7>
        <v-text-field
          type="text"
          :label="
            formulario.juridico == 1 ? 'Razón Social' : 'Nombre y Apellido'
          "
          v-model="formulario.nombre"
        />
      </v-flex>
      <v-flex px-5 xs5>
        <v-text-field
          type="text"
          label="Teléfono"
          v-model="formulario.telefono"
        />
      </v-flex>
      <v-flex px-5 xs7>
        <v-text-field
          type="correo"
          label="Correo"
          v-model="formulario.correo"
        />
      </v-flex>
      <v-flex px-5 xs5>
        <v-select :items="estados" v-model="formulario.estado" label="Estado" />
      </v-flex>
      <v-flex px-5 xs12>
        <v-textarea label="Escriba su Mensaje" v-model="formulario.mensaje" />
      </v-flex>
      <v-flex px-5 text-center xs6>
        <v-btn color="error" @click="clear" text class="mb-3">Limpiar</v-btn>
      </v-flex>
      <v-flex px-5 text-center xs6>
        <v-btn color="primary" @click="onSend" :disabled="button" class="mb-3"
          >Enviar</v-btn
        >
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import Separador from "../components/Separador.vue";
export default {
  props: {
    correo: {
      type: String,
    },
  },
  components: {
    Header,
    Separador,
  },
  data() {
    return {
      button: false,
      prerif: "J-",
      formulario: {
        juridico: 0,
        rif: "",
        nombre: "",
        telefono: "",
        estado: "",
        correo: "",
        mensaje: "",
      },
      estados: [
        "Amazonas",
        "Anzoategui",
        "Apure",
        "Aragua",
        "Barinas",
        "Bolivar",
        "Carabobo",
        "Cojedes",
        "Delta Amacuro",
        "Distrito Capital",
        "Falcón",
        "Guárico",
        "Lara",
        "Mérida",
        "Miranda",
        "Monagas",
        "Nueva Esparta",
        "Portuguesa",
        "Sucre",
        "Táchira",
        "Trujillo",
        "Guaira",
        "Yaracuy",
        "Zulia",
      ],
    };
  },
  methods: {
    clear() {
      this.formulario = {
        rif: "",
        nombre: "",
        telefono: "",
        estado: "",
        correo: "",
        mensaje: "",
      };
    },
    handleSubmit() {
      var qs = require("qs");
      if (this.correo == "Default") {
        if (
          this.formulario.estado == "Amazonas" ||
          this.formulario.estado == "Anzoategui" ||
          this.formulario.estado == "Bolivar" ||
          this.formulario.estado == "Delta Amacuro" ||
          this.formulario.estado == "Distrito Capital" ||
          this.formulario.estado == "Miranda" ||
          this.formulario.estado == "Monagas" ||
          this.formulario.estado == "Nueva Esparta" ||
          this.formulario.estado == "Sucre" ||
          this.formulario.estado == "Guaira"
        ) {
          this.formulario.tienda = "mayor.ccs@ofimania.com";
        }
        if (
          this.formulario.estado == "Apure" ||
          this.formulario.estado == "Guárico" ||
          this.formulario.estado == "Aragua"
        ) {
          this.formulario.tienda = "mayor.ara@ofimania.com";
        }
        if (
          this.formulario.estado == "Barinas" ||
          this.formulario.estado == "Carabobo" ||
          this.formulario.estado == "Cojedes" ||
          this.formulario.estado == "Falcón" ||
          this.formulario.estado == "Lara" ||
          this.formulario.estado == "Mérida" ||
          this.formulario.estado == "Portuguesa" ||
          this.formulario.estado == "Táchira" ||
          this.formulario.estado == "Trujillo" ||
          this.formulario.estado == "Yaracuy" ||
          this.formulario.estado == "Zulia"
        ) {
          this.formulario.tienda = "mayorval@ofimania.com";
        }
      } else {
        this.formulario.tienda = this.correo;
      }
      this.formulario.rif = this.prerif + this.formulario.rif;
      console.log(this.formulario);
      this.axios
        .post(
          "https://www.ofimania.com/API/Formulario/Contacto",
          qs.stringify(this.formulario),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.error == null) {
            this.clear();
            this.button = false;
            this.$store.state.alerta = {
              estado: true,
              tipo: "Bien",
              titulo: "Correo Enviado!",
              info: "Pronto estaremos en contacto.",
            };
          } else {
            this.$store.state.alerta = {
              estado: true,
              tipo: "error",
              titulo: "Error",
              info:
                "Parece un error de red, verifica que estes conectado a internet",
            };
          }
        })
        .catch((err) => {
          this.button = false;
          this.$store.state.alerta = {
            estado: true,
            tipo: "error",
            titulo: "Error",
            info:
              "Parece un error de red, verifica que estes conectado a internet",
          };
        });
    },
    onSend() {
      if (this.formulario.juridico == 1 && !this.formulario.rif) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "alerta",
          titulo: "Introduce tu Rif",
          info: "Es necesario un Rif",
        };
      } else if (!this.formulario.nombre) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "alerta",
          titulo: "Introduce tu Nombre",
          info: "Es necesario un nombre",
        };
      } else if (!/^([0-9]+){11}$/.test(this.formulario.telefono)) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "alerta",
          titulo: "Introduce tu Numero de Telefono",
          info: "El telefono no parece ser correcto",
        };
      } else if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(
          this.formulario.correo
        )
      ) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "alerta",
          titulo: "Introduce tu Correo",
          info: "El correo no parece ser correcto",
        };
      } else if (this.formulario.estado == null) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "alerta",
          titulo: "Selecciona tu Estado",
          info: "El estado no puede quedar en blanco",
        };
      } else if (!this.formulario.mensaje) {
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Introduce un mensaje",
          info: "Es necesario un mensaje para enviar el correo",
        };
      } else {
        this.$store.state.alerta = {
          estado: true,
          tipo: "bien",
          titulo: "Enviando...",
          info: "Esto puede tomar unos minutos.",
        };
        this.button = true;
        this.handleSubmit();
      }
    },
  },
  computed: {
    ...mapState(["movil"]),
  },
};
</script>
