<template>
  <v-layout column fill-height>
    <Header
      titulo=""
      imagen="https://www.ofimania.com/img/Header/Contacto.webp"
    />
    <v-layout align-center xs10 justify-center title>
      <Separador titulo="Nuestras Redes" />
    </v-layout>
    <v-layout wrap align-center mt-3 text-center justify-center>
      <v-flex grow xs6 md3 my-5>
        <v-btn
          class="mx-1"
          color="purple"
          icon
          href="https://www.instagram.com/ofimania"
          target="blank"
        >
          <v-icon size="100px">mdi-instagram</v-icon>
        </v-btn>
        <v-flex grow mt-5>
          <span>Instagram</span>
        </v-flex>
      </v-flex>
      <v-flex grow xs6 md3 mt-3>
        <ContactButton />
        <v-flex grow mt-5>
          <span>WhatsApp</span>
        </v-flex>
      </v-flex>
      <v-flex grow xs6 md3 my-5>
        <v-btn
          class="mx-1"
          color="primary"
          icon
          href="https://www.facebook.com/ofimaniaoficial"
          target="blank"
        >
          <v-icon size="100px">mdi-facebook-box</v-icon>
        </v-btn>
        <v-flex grow mt-5>
          <span>Facebook</span>
        </v-flex>
      </v-flex>
      <v-flex grow xs6 md3 my-5>
        <v-btn
          class="mx-1"
          color="info"
          icon
          href="https://twitter.com/ofimania"
          target="blank"
        >
          <v-icon size="100px">mdi-twitter-box</v-icon>
        </v-btn>
        <v-flex grow mt-5>
          <span>Twitter</span>
        </v-flex>
      </v-flex>
    </v-layout>
    <Formulario :correo="correo" />
  </v-layout>
</template>
<script>
import Header from "../components/Header.vue";
import Separador from "../components/Separador.vue";
import Formulario from "../components/Formulario.vue";
import ContactButton from "../components/ContactButton.vue";

export default {
  metaInfo: {
    title: "Contacto | Ofimania",
  },
  props: {
    correo: {
      type: String,
      default: "Default",
    },
  },
  components: {
    Header,
    Separador,
    Formulario,
    ContactButton,
  },
};
</script>
