<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable max-width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-1" color="light-green" icon v-bind="attrs" v-on="on">
          <v-icon size="100px">mdi-whatsapp</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Escribenos por WhatsApp</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <v-list flat>
            <v-list-item-group color="primary">
              <v-list-item
                :href="
                  'https://api.whatsapp.com/send?phone=584149299013&text=¡Quiero%20conocer%20m%C3%A1s%20sobre%20sus%20productos%20y%20servicios!'
                "
                target="blank"
              >
                <v-icon color="green" class="mr-2">mdi-whatsapp</v-icon>
                <v-list-item-title> Principal </v-list-item-title>
              </v-list-item>
              <div v-for="(item, i) in data" :key="i">
                <v-subheader
                  v-if="item.nombre == 'Detales' || item.nombre == 'Mayores'"
                  v-text="item.nombre"
                ></v-subheader>
                <v-list-item
                  v-else-if="item.nombre != 'Banner'"
                  :href="
                    'https://api.whatsapp.com/send?phone=' +
                      item.whatsapp +
                      '&text=¡Quiero%20conocer%20m%C3%A1s%20sobre%20sus%20productos%20y%20servicios!'
                  "
                  target="blank"
                >
                  <v-icon color="green" class="mr-2">mdi-whatsapp</v-icon>
                  <v-list-item-title v-text="item.nombre"> </v-list-item-title>
                </v-list-item>
              </div>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      dialog: false,
    };
  },
  created() {
    this.$store.state.cargando = true;
    this.axios
      .get("https://www.ofimania.com/API/Tienda/Get/GetContacto")
      .then((response) => {
        this.data = response.data;
        this.$store.state.cargando = false;
      })
      .catch((error) => {
        this.$store.state.cargando = false;
        this.$store.state.alerta = {
          estado: true,
          tipo: "error",
          titulo: "Error de Conexion",
          info: "Verifique su Conexion a Internet",
        };
      });
  },
};
</script>
